import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import { InputMessageTypes } from '@platform-ui-kit/components-library/dist/types/types/common'
import { WppInputCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppInput,
  WppIconSearch,
  WppIconClose,
  WppTypography,
  WppButton,
  WppIconPlus,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { useFetchApps } from 'api/assistant/queries/apps/useFetchApps'
import { useFetchToolbarApps } from 'api/assistant/queries/apps/useFetchToolbarApps'
import IconEmptyState from 'assets/icons/no-result.svg'
import { AppItem } from 'components/assistant/apps/appItem/AppItem'
import styles from 'components/assistant/assistantPopover/assistantAllApps/AssistantAllApps.module.scss'
import { Flex } from 'components/common/flex/Flex'

export interface Props {
  onNewChat: () => void
  assistantWidth?: number
}

export const AssistantAllApps = ({ onNewChat, assistantWidth = 440 }: Props) => {
  const { osContext } = useOs()

  const { data: appsAPI } = useFetchApps({
    params: { itemsPerPage: 5000, tenantId: osContext.tenant.id },
  })
  const { data: toolbarApps } = useFetchToolbarApps({
    params: { userId: osContext.userDetails.id },
  })

  const TABS = ['All', 'Pinned']

  const [searchInput, setSearchInput] = useState('')
  const [debouncedInput, setDebouncedInput] = useState('')

  const [searchErrorMessage, setSearchErrorMessage] = useState('')
  const [messageType, setMessageType] = useState<InputMessageTypes | undefined>(undefined)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchInput.length === 0 || (searchInput.length >= 3 && searchInput.length <= 20)) {
        setDebouncedInput(searchInput)
        setMessageType(undefined)
        setSearchErrorMessage('')
      } else {
        setDebouncedInput('')
        setMessageType('error')
        setSearchErrorMessage('Search value must be between 3 and 20 characters')
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const handleSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    setSearchInput(event.target.value)
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index)
  }

  return (
    <div style={{ paddingLeft: '8px', width: '100%', position: 'relative', paddingRight: '20px' }}>
      <WppInput
        name="search"
        placeholder="Find a tool..."
        value={searchInput}
        message={searchErrorMessage}
        messageType={messageType}
        required
        className="cancel-drag"
        onWppChange={handleSearchChange}
      >
        <WppIconSearch slot="icon-start" aria-label="Search icon" />
        {searchInput.length !== 0 && (
          <WppIconClose
            slot="icon-end"
            aria-label="Clear icon"
            onClick={() => {
              setSearchInput('')
              setDebouncedInput('')
            }}
          />
        )}
      </WppInput>

      {/* Tabs */}
      {!debouncedInput && (
        <Flex className={clsx(styles.tabs)} align="center" gap={0}>
          {TABS.map((tab, index) => {
            return (
              <WppTypography
                key={index}
                className={clsx(
                  styles.tab,
                  styles.blackS,
                  selectedTabIndex === index && styles['tab-active'],
                  'cancel-drag',
                )}
                tag="span"
                type="s-strong"
                onClick={() => handleTabChange(index)}
              >
                {tab}
              </WppTypography>
            )
          })}
          <div className={styles.line} />
        </Flex>
      )}

      <div className={clsx(styles.container, 'cancel-drag')}>
        {selectedTabIndex === 0 && (
          <>
            {appsAPI
              // .filter(app => !toolbarApps.pinnedApps.some(pinnedApp => app.id === pinnedApp.id))
              .filter(app =>
                debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
              )
              .map(app => (
                <AppItem key={app.id} app={app} assistantWidth={assistantWidth} type="all-apps" />
              ))}
          </>
        )}

        {selectedTabIndex === 1 && (
          <>
            {toolbarApps.pinnedApps
              .filter(app =>
                debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
              )
              .map(app => (
                <AppItem key={app.id} app={app} assistantWidth={assistantWidth} type="all-apps" />
              ))}
          </>
        )}

        {appsAPI.filter(app =>
          debouncedInput.length ? app.name.toLowerCase().includes(debouncedInput.toLowerCase()) : true,
        ).length === 0 && (
          <div className={styles.mt40}>
            <Flex justify="center">
              <img src={IconEmptyState} alt="No tools found" />
            </Flex>
            <Flex justify="center" className={styles.mt24}>
              <WppTypography type="m-body">No tools found</WppTypography>
            </Flex>
            <Flex justify="center" className={styles.mt8}>
              <WppTypography type="s-body">Try a different search term or ask AI</WppTypography>
            </Flex>
            <Flex justify="center" className={styles.mt24}>
              <WppButton onClick={onNewChat}>
                <WppIconPlus slot="icon-start" />
                New chat
              </WppButton>
            </Flex>
          </div>
        )}
      </div>
    </div>
  )
}
